











import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class DataTable extends Vue {

    @Prop()
    itemData!: any

    get headers(): unknown {
        return [
            {
                text: this.itemHeader["name"],  // Use meter name as first header
                align: 'start',
                sortable: false,
                value: 'name'
            },
            {
                text: this.itemHeader["val"],   // Use meter unit symbol as second header
                align: 'start',
                sortable: false,
                value: 'val'
            }
        ]
    }

    get itemHeader(): any {
        return this.itemData["header"]
    }

    get itemValues(): any {
        return this.itemData["values"]
    }

}

