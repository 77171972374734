













































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'

import MeterInfoCard from '@/components/MeterInfoCard.vue'
import DataGraph from '@/components/DataGraph.vue'
import DataTable from '@/components/DataTable.vue'
import { NodeInfo, MeterUnit, ShownUnit, MeterWithMeterUnits, Data } from '@/types/state'

type DataType = {
    x: number, // A Date as a number of milliseconds sinds 1 Jan 1970
    y: string, // AVG value
    yMin: string,   // Min value
    yMax: string    // Max value
}

type DataGraphComponent = Vue & {
    mounted: boolean,
    addDataset(meterUnitId: number, data: DataType[],
      label: string|undefined, unitSymbolAsNumber: number,
      unitName: string, graphColor: string): void,
    removeDataset(meterUnitId: number, unitSymbolAsNumber: number): void,
    reset(): void
}

type RecordsSplit = {
    // timestamps: Array<Date>,
    timestamps: number[],
    values: number[],
    minValues: number[],
    maxValues: number[],
    decimals: number
}


@Component({
    components: {
      MeterInfoCard,
      DataGraph,
      DataTable
    }
})
export default class DataPage extends Vue {
  isMounted = false

  labels = []
  records = []

  startDateMenu = false
  endDateMenu = false

  startDate = new Date(Date.now() - 1000*60*60*24*7).toISOString().substr(0, 10)
  endDate = new Date(Date.now()).toISOString().substr(0, 10)

  refreshGraphData(): void {
    // this.dataGraph.reset()
    this.$store.commit('detailNode/SET_DATA_LOADED', false)

    this.shownUnitsWithinDateRange.forEach(u => {
        const meterUnitId = u.meterUnitId
        const meterName = this.getMeterName(meterUnitId)
        const unitName = this.getUnitName(meterUnitId)
        const unitSymbol = this.getUnitSymbol(meterUnitId)
        const graphColor = "#" + u.graphColorHex
        if (meterName && unitSymbol) {
          const recordArrays = this.splitRecordsToArrays(u, undefined)
          const fixedUnitAndValues = this.fixUnitAndData(unitSymbol, u.decimals, recordArrays)
          const x = recordArrays.timestamps
          const y = fixedUnitAndValues.values
          const yMin = fixedUnitAndValues.minValues
          const yMax = fixedUnitAndValues.maxValues
          let data: DataType[] = []
          for (let i = 0; i < x.length; i++) {
            data.push({x: x[i], y: y[i], yMin: yMin[i], yMax: yMax[i]})
          }

          this.dataGraph.addDataset(
            meterUnitId,
            data,
            unitName,
            this.convertStringToNumber(fixedUnitAndValues.symbol),
            fixedUnitAndValues.symbol,
            graphColor
          )
        }
    })

    this.$store.commit('detailNode/SET_DATA_LOADED', true)
  }

  fixUnitAndData(unitSymbol: string, decimals: number, recordsSplit: RecordsSplit): {
    symbol: string, values: string[], minValues: string[], maxValues: string[]
  } {
    let values = recordsSplit.values
    let minValues = recordsSplit.minValues
    let maxValues = recordsSplit.maxValues

    let newSymbol = ""
    switch (unitSymbol) {
        case "Wh":
            newSymbol = "kWh"
            values = values.map(v => v/1000)
            minValues = minValues.map(v => v/1000)
            maxValues = maxValues.map(v => v/1000)
            break
        case "J":
            newSymbol = "MJ"
            values = values.map(v => { return v/1000000 })
            minValues = minValues.map(v => { return v/1000000 })
            maxValues = maxValues.map(v => { return v/1000000 })
            break
        case "GJ":
            // 20240228
            // Sometimes values that are sent as GJ values are actually in J.
            // In this case, the values should be divided by 1,000,000,000.
            values = values.map(v => v >= 100_000 ? v/1_000_000_000 : v)
            minValues = minValues.map(v => v >= 100_000 ? v/1_000_000_000 : v)
            maxValues = maxValues.map(v => v >= 100_000 ? v/1_000_000_000 : v)
            break
        case "m^3":
            newSymbol = "m³"
            break
        case "m^3/h":
            newSymbol = "m³/h"
            break
        case "m^3/s":
            newSymbol = "m³/s"
            break
        default:
            newSymbol = unitSymbol
      }
      return {
        symbol: newSymbol,
        values: values.map(v => this.roundValue(v, decimals)),
        minValues: minValues.map(v => this.roundValue(v, decimals)),
        maxValues: maxValues.map(v => this.roundValue(v, decimals))
      }
  }

  @Watch("startDate")
  updateStartDateInState(): void {
      this.$store.commit('detailNode/SET_START_DATE', this.startDate)
  }
  
  @Watch("endDate")
  updateEndDateInState(): void {
      this.$store.commit('detailNode/SET_END_DATE', this.endDate)
  }


  formatDate(date: string): string {
    return date.substring(8, 10) + "/" + date.substring(5, 7) + "/" + date.substring(0, 4)
  }

  get startDateText(): string {
    return this.formatDate(this.startDate)
  }

  get endDateText(): string {
    return this.formatDate(this.endDate)
  }


  getMeterById(meterUnitId: number): MeterWithMeterUnits|undefined {
      return this.meters.find(m => m.unitIds.includes(meterUnitId))
  }

  getMeterName(meterUnitId: number): string|undefined {
      const meter = this.getMeterById(meterUnitId)
      return meter ? meter.name : undefined
  }

  getUnitSymbol(meterUnitId: number): string|undefined {
      const meter = this.getMeterById(meterUnitId)
      if (meter) {
          const meterUnit: MeterUnit|undefined = meter.displayedMeterUnitsWithData.find(dmu => dmu.meterUnitId == meterUnitId)
          return meterUnit ? meterUnit.symbol : undefined
      }
      return undefined
  }

  getUnitName(meterUnitId: number): string|undefined {
      const meter = this.getMeterById(meterUnitId)
      if (meter) {
          const meterUnit: MeterUnit|undefined = meter.displayedMeterUnitsWithData.find(dmu => dmu.meterUnitId == meterUnitId)
          let name: string|undefined = undefined
          if (meterUnit) {
            if (meterUnit.displayName) name = meterUnit.displayName
            else if (meterUnit.referenceName) name = meterUnit.referenceName
          }
          return name
      }
      return undefined
  }


  splitRecordsToArrays(records: ShownUnit, unitSymbol: string|undefined): RecordsSplit {
      type DataWithNumAsTime = { timestamp: number, value: number, minValue: number, maxValue: number }
      const sorted = records.data
          .map(d => ({ timestamp: d.timestamp.valueOf(), value: d.value, minValue: d.minValue, maxValue: d.maxValue }))
          .sort((a: DataWithNumAsTime, b: DataWithNumAsTime) => a.timestamp.valueOf() - b.timestamp.valueOf())
      return {
          timestamps: sorted.map(r => r.timestamp),
          values: sorted.map(r => !unitSymbol ? r.value : (unitSymbol == "J" ? r.value*1000000000 : r.value)),
          minValues: sorted.map(r => !unitSymbol ? r.minValue : (unitSymbol == "J" ? r.minValue*1000000000 : r.minValue)),
          maxValues: sorted.map(r => !unitSymbol ? r.maxValue : (unitSymbol == "J" ? r.maxValue*1000000000 : r.maxValue)),
          decimals: records.decimals
          // This is a temporary solution for meters that actually send GJ-data but are interpreted as J-data.
          // This should be fixed within the EMRA-boxes themselves soon.
      }
  }


  fixValueBasedOnUnit(unitSymbol: string|undefined, value: number, roundToDecimals = 3): string {
      switch (unitSymbol) {
          case "Wh":    value/=1000; break
          case "J":     
            // if (this.detailNode?.alias == "Rubroek") value *= 1000
            // else value/=1000000
            value/=1000000
            break
          case "GJ":
            // 20240228
            // Sometimes values that are sent as GJ values are actually in J.
            // In this case, the values should be divided by 1,000,000,000.
            if (value >= 100_000) {
                value /= 1_000_000_000
            }
            break
          default:      break
      }
      return this.roundValue(value, roundToDecimals)
  }

  fixUnitSymbol(unitSymbol: string|undefined): string {
      switch(unitSymbol) {
          case "Wh":    return "kWh"
          case "J":     return "MJ"
          case "m^3":   return "m³"
          case "m^3/h": return "m³/h"
          case "m^3/s": return "m³/s"
          default:      return unitSymbol ? unitSymbol : ""
      }
  }

  // https://stackoverflow.com/a/59575616
  roundValue(val: number, decimals: number): string {
      return Number(Math.round(Number(val + 'e' + decimals)) + 'e-' + decimals).toFixed(decimals);
  }


  get shownUnits(): Array<ShownUnit> {
      return this.$store.getters['detailNode/ShownUnits']
  }

  get shownUnitsWithinDateRange(): Array<ShownUnit> {
      return this.$store.getters['detailNode/ShownUnitsWithinDateRange']
  }

  getShownUnitTableData(shownUnit: ShownUnit): any {
      const dataFunc = shownUnit.dataFunc
      if (!dataFunc) return undefined
      if (!shownUnit.data || shownUnit.data.length == 0) return undefined

      const meterUnitId = shownUnit.meterUnitId
      const unitSymbol = this.getUnitSymbol(meterUnitId)

      const minVals = shownUnit.data.map(d => d.minValue)
      const maxVals = shownUnit.data.map(d => d.maxValue)
      
      let minRaw, maxRaw, calcRaw
      if (dataFunc == "Average") {
          const vals = shownUnit.data.map(d => d.value)
          minRaw = Math.min(...minVals)
          maxRaw = Math.max(...maxVals)
          calcRaw = vals.reduce((a, b) => a + b)/vals.length
      } else {  // if dataFunc == "Difference"
          minRaw = minVals[0]
          maxRaw = maxVals[maxVals.length - 1]
          calcRaw = maxRaw - minRaw
      }

      const min = this.fixValueBasedOnUnit(unitSymbol, minRaw, shownUnit.decimals ?? 3)
      const max = this.fixValueBasedOnUnit(unitSymbol, maxRaw, shownUnit.decimals ?? 3)
      const calc = this.fixValueBasedOnUnit(unitSymbol, calcRaw, shownUnit.decimals ?? 3)


      const tableHeaderName = this.getMeterName(meterUnitId)
      const fixedUnitSymbol = this.fixUnitSymbol(unitSymbol)
      const tableHeaderVal = this.getUnitName(meterUnitId) + (fixedUnitSymbol != "" ? " [" + fixedUnitSymbol + "]" : "")

      let calcString = ""
      switch (dataFunc) {
          case "Average":
              calcString = "Gemiddelde"
              break
          case "Difference":
              calcString = "Verschil"
              break
          default:
              calcString = "-"
      }

      const itemHeader = {
          "name": tableHeaderName,
          "val": tableHeaderVal
      }

      const itemValues = [
          {
              "name": "Minimum",
              "val": min
          },
          {
              "name": calcString,
              "val": calc
          },
          {
              "name": "Maximum",
              "val": max
          }
      ]

      return { header: itemHeader, values: itemValues }
  }

  convertStringToNumber(str: string): number {
      let numStr = ""
      for (let c = 0; c < str.length; c++) {
          numStr += str.charCodeAt(c).toString() + " ";
      }
      return parseInt(numStr.replaceAll(" ", ""), 10)
  }


  @Watch('shownUnitsWithinDateRange', {deep: true})
  shownUnitsChanged(newUnits: Array<ShownUnit>, oldUnits: Array<ShownUnit>): void {
    if (this.$refs.dataGraph) {
        this.$store.commit('detailNode/SET_DATA_LOADED', false)

        if (newUnits.length != oldUnits.length) {
            const unitsWithinRange = this.shownUnitsWithinDateRange

            oldUnits.forEach(ou => {
                if (!newUnits.find(nu => nu.meterUnitId == ou.meterUnitId)) {
                    const unitSymbol = this.getUnitSymbol(ou.meterUnitId) ?? ""
                    this.dataGraph.removeDataset(ou.meterUnitId, this.convertStringToNumber(unitSymbol))
                }
            })

            newUnits.forEach(nu => {
                if (!oldUnits.find(ou => ou.meterUnitId == nu.meterUnitId)) {
                    const recs = unitsWithinRange.find(u => u.meterUnitId == nu.meterUnitId)
                    const meterName = this.getMeterName(nu.meterUnitId)
                    const unitName = this.getUnitName(nu.meterUnitId)
                    const unitSymbol = this.getUnitSymbol(nu.meterUnitId)
                    const graphColor = "#" + nu.graphColorHex
                    if (recs && meterName && unitSymbol) {
                      const recordArrays = this.splitRecordsToArrays(nu, undefined)
                      const fixedUnitAndValues = this.fixUnitAndData(unitSymbol, nu.decimals, recordArrays)
                      const x = recordArrays.timestamps
                      const y = fixedUnitAndValues.values
                      const yMin = fixedUnitAndValues.minValues
                      const yMax = fixedUnitAndValues.maxValues
                      let data: DataType[] = []
                      for (let i = 0; i < x.length; i++) {
                        data.push({x: x[i], y: y[i], yMin: yMin[i], yMax: yMax[i]})
                      }

                      this.dataGraph.addDataset(
                        nu.meterUnitId,
                        data,
                        unitName,
                        this.convertStringToNumber(unitSymbol),
                        fixedUnitAndValues.symbol,
                        graphColor
                      )
                    }
                }
            })
        } else {
            this.refreshGraphData()
        }

        this.$store.commit('detailNode/SET_DATA_LOADED', true)
    }
  }


  get detailNode(): NodeInfo {
    return this.$store.getters['detailNode/NodeInfo']
  }

  get meters(): Array<MeterWithMeterUnits> {
    return this.$store.getters['detailNode/Meters']
  }

  get isLoaded(): boolean {
    return this.$store.getters['detailNode/IsPreloaded']
  }

  get isDataLoaded(): boolean {
    return this.$store.getters['detailNode/IsDataLoaded']
  }

  get dataGraph(): DataGraphComponent {
      return this.$refs.dataGraph as DataGraphComponent
  }

  created(): void {
      this.isMounted = false
      this.$store.dispatch('detailNode/init')
  }

  mounted(): void {
    this.isMounted = true
  }


}
